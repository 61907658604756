// This scirpt is used for handling all the statistics from the stored statistic

// React imports
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

// Import user actions
import { addToPortfolioAction } from '../actions/userActions';

//Import cookio library
import Cookies from 'js-cookie';

// Boot strap functions ----------------
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';

// Chart imports --------------
import { PieChart, Pie, Cell } from 'recharts'; //Pie Chart
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'; // LineChart

const ReturnLineChart = () => {
    const [state_stocks_return_linechart, set_stock_return] = useState([]); // The list holding the stock returns
    const [state_stock_checklist, set_stock_checklist] = useState({});

    const dispatch = useDispatch();
    const store_stock_returns = useSelector((state) => state.statistics.PortfolioStatistics.data);

    const COLORS = [
        '#2C3E50', // Blue-Grey
        '#8E44AD', // Dark Purple
        '#2980B9', // Medium Blue
        '#27AE60', // Dark Green
        '#E74C3C', // Dark Red
        '#F39C12', // Dark Yellow/Gold
        '#D35400', // Burnt Orange
        '#16A085', // Teal
        '#34495E', // Steel Blue
        '#7F8C8D', // Grey
        '#C0392B', // Deep Red
        '#9B59B6', // Medium Purple
        '#F1C40F', // Bright Yellow
        '#E67E22', // Orange
        '#1ABC9C', // Bright Teal
    ];


    // useEffect when the store with stock prices are updated
    const handle_checklist_change = (e) => {
        // console.log(e);
        var new_state_stock_checklist = {
            ...state_stock_checklist
        }
        if (new_state_stock_checklist[e] == true) {
            new_state_stock_checklist[e] = false
        } else {
            new_state_stock_checklist[e] = true
        }
        // console.log(new_state_stock_checklist);
        set_stock_checklist(new_state_stock_checklist);
    };

    // useEffect when the store with stock prices are updated
    useEffect(() => {
        // Gå ut av denne useEffecten om store_statistics ikke er satt
        if (!store_stock_returns || Object.keys(store_stock_returns).length === 0) {
            return; // Exit the useEffect early
        }

        var stock_checklist = {}

        var stock_return_data_day = {};
        var stock_return_linechart_format = []
        // console.log(store_stock_returns);
        store_stock_returns['Date'].map((item, index) => {
            stock_return_data_day = {};
            Object.keys(store_stock_returns).map((key, index2) => {
                // console.log('Key:', key);
                // console.log(store_statistics.data[key][index]);
                if (key == "Date") {
                    const date = new Date(store_stock_returns[key][index]);
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
                    const day = String(date.getDate()).padStart(2, '0');
                    // console.log(stock_return_data_day[key]);
                    stock_return_data_day[key] = `${year}-${month}-${day}`;
                } else {
                    stock_return_data_day[key] = store_stock_returns[key][index];
                }
            });
            // console.log(stock_return_data_day);
            stock_return_linechart_format.push(stock_return_data_day);
        });

        Object.keys(store_stock_returns).map((key, index) => {
            if (key !== "Date") {
                stock_checklist[key] = true
            }
        })
        // console.log(stock_checklist);

        set_stock_return(stock_return_linechart_format);
        set_stock_checklist(stock_checklist);
    }, [store_stock_returns]);


    return (
        <div>
            <h2>Daily Stock Return</h2>
            <Row>
                <Col>
                    {Object.keys(state_stock_checklist).map((key, index) => (
                        key !== 'Date' ? (
                            <label>
                                <span>{key}: </span>
                                <input
                                    type="checkbox"
                                    checked={state_stock_checklist[key]}
                                    onChange={() => handle_checklist_change(key)}
                                    style={{ marginRight: '8px' }}
                                />
                            </label>
                        ) : null
                    ))}
                </Col>
            </Row>
            <Row>
                <Col>
                    {state_stocks_return_linechart.length > 0 ? (
                        <Container className='Portfolio_Risk_Container'>
                            <LineChart
                                width={2500}
                                height={400}
                                data={state_stocks_return_linechart}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="Date" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                {Object.keys(state_stocks_return_linechart[0]).map((key, index) => (
                                    key !== 'Date' && state_stock_checklist[key] == true ? (
                                        <Line type="monotone" dataKey={key} stroke={COLORS[index % COLORS.length]} activeDot={{ r: 8 }} />
                                    ) : <p>No Data</p>
                                ))}
                            </LineChart>
                        </Container>
                    ) : null}
                </Col>
            </Row>
        </div>
    );
}

export default ReturnLineChart;