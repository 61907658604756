import React from 'react';
import "../CSS/App.css"
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';

const LogIn = () => {

    return (
        <div>
            <Authenticator></Authenticator>
        </div>
    );
};

export default LogIn;
