import React, { useState, useEffect } from "react";
import "./CSS/App.css";
import "@aws-amplify/ui-react/styles.css";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';

// import SideAdd from "./Adds/Adds";
// import AdSense from "./Adds/Adsense";
import Header from "./Header/header"
import About from "./ExtraPages/About"
import Disclaimer from "./ExtraPages/Disclaimer";
import LogIn from "./ExtraPages/LogIn";

import { GetStockListButton, AnalyzePortfolioButton } from "./stockFunctions/stockFunctions";
import Checklist from './stockFunctions/Checklist';
import StockRisk from './stockFunctions/statistics';
import ReturnLineChart from "./stockFunctions/lineChart";

import { Amplify } from 'aws-amplify';
import config from './aws-exports';
import { withAuthenticator, Authenticator, useAuthenticator } from "@aws-amplify/ui-react";

Amplify.configure(config);

const App = () => {

  const { user } = useAuthenticator();

  const Main = () =>
    <Container fluid>
      <Row>
        <Col>
          <Header />
        </Col>
      </Row>
      <Row>
        <Col md={8} className="mx-auto">
          <Alert key='warning' variant='warning'
            style={{ textAlign: 'center' }}>
            <Alert.Link href="Disclaimer">Read the disclaimer before proceeding!</Alert.Link>
          </Alert>
          <div className="App-Main-Content">
            <GetStockListButton />
            <Checklist />
            <AnalyzePortfolioButton />
            <StockRisk />
            <ReturnLineChart />
          </div>
        </Col>
      </Row>
    </Container >;

  const AboutPage = () =>
    <Container fluid>
      <Row>
        <Header />
      </Row>
      <Row>
        <Col md={8} className="mx-auto">
          <div className="App-Main-Content">
            <About />
          </div>
        </Col>
      </Row>
    </Container>;

  const DisclamerPage = () =>
    <Container fluid>
      <Row>
        <Header />
      </Row>
      <Row>
        <Col md={8} className="mx-auto">
          <div className="App-Main-Content">
            <Disclaimer />
          </div>
        </Col>
      </Row>
    </Container>;

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/About" element={<AboutPage />} />
        <Route path="/Disclaimer" element={<DisclamerPage />} />
        <Route path="/LogIn" element={user ? <Navigate to="/" /> : <LogIn />} />
      </Routes>
    </Router>
  );
};

export default App;