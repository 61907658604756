// actions/userActions.js

const setUserNameAction = (name) => ({
  type: 'SET_USER_NAME',
  payload: name,
});

// The user adds the portfolio to the 
const deletePortfolioAction = (portfolio) => ({
  type: 'ADD_TO_PORTFOLIO',
  payload: portfolio
});

// The user adds the portfolio to the 
const addToPortfolioAction = (portfolio) => ({
  type: 'ADD_TO_PORTFOLIO',
  payload: portfolio
});


// Set AWS stocklist sets the list of stocks received from the 
const setAWSStocklistAction = (AWSStocklist) => ({
  type: 'GET_AWS_STOCK_LIST',
  payload: AWSStocklist
});

// Set AWS stocklist sets the list of stocks received from the 
const setPortfolioStatisticsAction = (statistics) => ({
  type: 'SET_PORTFOLIO_STATISTICS',
  payload: statistics
});


export { setUserNameAction, setAWSStocklistAction, addToPortfolioAction, setPortfolioStatisticsAction, deletePortfolioAction }
