import React, { useState } from 'react';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import { Col, Row } from 'react-bootstrap';

import '../CSS/Header.css'


const Header = () => {
  const { user, signOut } = useAuthenticator();

  const header =
    <Navbar expand="lg" className="header-class">
      <Container className='container-class' fluid>
        <Navbar.Brand size="lg" href="/">StockRisk</Navbar.Brand>
        <Navbar.Brand size="lg" href="/">Stock Portfolio Analytics tools</Navbar.Brand>
        <Nav className="me-auto">
          <Nav.Link href="About">About</Nav.Link>
          <Nav.Link href="Disclaimer">Disclaimer</Nav.Link>
        </Nav>
        {user ? (
          <p className='headerText'>Logged in using: {user.attributes.email}</p>
        ) : <p className='headerText'>Not logged in!</p>}
        <Button variant="outline-light" onClick={signOut} disabled={!user}>Sign out</Button>
        <Button variant="outline-light" href='LogIn' disabled={user}>Log in</Button>
      </Container>
    </Navbar>

  return header
};

export default Header;