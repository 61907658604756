import React from 'react';
import ReactDOM from 'react-dom/client';
import './CSS/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { withAuthenticator, Authenticator, useAuthenticator } from "@aws-amplify/ui-react";


//REDUX IMPORTS
import { Provider, useStore } from 'react-redux';
import store from './store';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <Authenticator.Provider>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Authenticator.Provider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
