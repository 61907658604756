import React from 'react';
import "../CSS/App.css"

import { Container } from 'react-bootstrap';

const About = () => {

  const us = `
  StockRisk is a Norwegian based company aimed at providing investement tools to amataur investors! We believe everyone can become an investor with the right help!
  `
  const risk = `
  One of the key basises for every investement decision is risk, but without analytics tools its very hard to manage risk. Not only do you have the risk of a single stock,
  the stocks in your portfolio are linked by correlation. Without a statistical tool, handling the risk and correlation of stocks is next to impossible.
  In our Theory page (Coming soon) we explain the mathematics behind handling the risk, but it's not stricly needed for handling risk. What you need to do is to understand how correlation is
  affection you portfolio, and how it affect how diversified you are.
  `
  return (
    <div className='App'>
      <Container>
        <h2>About StockRisk</h2>
        <br></br>
        <h3>Us</h3>
        <p>{us}</p>
        <br></br>
        <h3>Risk and diversification</h3>
        <p>{risk}</p>

      </Container>

    </div>
  );
};

export default About;
